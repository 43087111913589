import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Checkbox,
  RadioButton,
  Select,
  Tooltip,
  DataTable,
  Button,
  Label,
  ContextualSaveBar,
  Icon,
  ChoiceList,
  Popover,
  Thumbnail,
  Stack,
  Card,
  TextStyle,
} from "@shopify/polaris";
import Skeleton from "./skeleton";
import noProduct from "./noProduct.png";
// import noProduct from "./";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { environment } from "../environments/environment";
import {
  ViewMinor,
  HideMinor,
  EditMinor,
  CircleChevronDownMinor,
  CircleChevronUpMinor,
} from "@shopify/polaris-icons";
// import { type } from "jquery";
class Formbuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form,
      updated: false,
      sync: props.sync !== undefined ? props.sync : false,
      buttonLoading: false,
      files: [],
      faqShowHide: [true],
      tableData: [],
      tableDataMarket: [],
      tableDataSelect: [],
      feeSettingsModify: [],
      validateForm: {},
      closeEye: false,
      activepop: false,
    };
  }

  render() {
    let url = environment.API_ENDPOINT;
    let newUrl = url.replace("/public/", "");
    return (
      <div className="row">
        {this.state.form === undefined ? (
          <div className="col-12">
            <Skeleton case="bodyconfig" />
          </div>
        ) : (
          this.state.form.map((field, index) => {
            if (this.props.title === "FAQs") {
              return (
                <div className="col-12 mt-1 mb-4" key={index}>
                  <Card.Section
                    title={field.ques}
                    actions={[
                      {
                        content: "Edit",
                        onAction: () => {
                          this.props.faqModal("edit", field);
                        },
                      },
                      {
                        content: "Delete",
                        destructive: true,
                        onAction: () => {
                          this.props.faqModal("delete", field);
                        },
                      },
                      {
                        content: (
                          <Icon
                            source={
                              this.state.faqShowHide[index]
                                ? CircleChevronDownMinor
                                : CircleChevronUpMinor
                            }
                            backdrop={true}
                          />
                        ),
                        onAction: () => {
                          let { faqShowHide } = this.state;
                          faqShowHide[index] = !faqShowHide[index];
                          this.setState({
                            faqShowHide,
                          });
                        },
                      },
                    ]}
                  >
                    {this.state.faqShowHide[index] && (
                      <div dangerouslySetInnerHTML={{ __html: field.ans }} />
                    )}
                  </Card.Section>
                </div>
              );
            } else if (field["Marketplace Fee"]) {
              return (
                <div className="col-12 mt-1 mb-4" key={index}>
                  <p>Marketplace Fee</p>
                  <DataTable
                    columnContentTypes={["select", "select", "text"]}
                    headings={[
                      "Marketplace",
                      "Fee Type",
                      "Fee Value",
                      "Action",
                    ]}
                    rows={this.props.rows}
                  />
                  <div className="p-4">
                    <Button
                      primary
                      onClick={() => {
                        this.props.addMore();
                      }}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
              );
            } else {
              if (
                (this.state.disableMarketPlace || this.state.disableaReviews) &&
                field.code !==
                "/general/sellers_review_rating_setting/order_status"
              ) {
                switch (field.type) {
                  case "select":
                    return (
                      <div className="col-12 mt-1 mb-1" key={index}>
                        <Select
                          label={
                            <Stack>
                              <span>{field.title}</span>
                              {field.mail_code && (
                                <Tooltip
                                  content={`Click to edit ${field.title} mail template`}
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.props.editMail(field.mail_code);
                                    }}
                                  >
                                    <Icon source={EditMinor}></Icon>
                                  </span>
                                </Tooltip>
                              )}
                            </Stack>
                          }
                          options={field.options}
                          labelInline={false}
                          helpText={field.help}
                          placeholder={"Choose"}
                          disabled={
                            field.code ===
                            "/general/vendorEmail/supplier_account_confirmation_notication"
                          }
                          onChange={(val) => {
                            this.handleChange(index, val, field.code, "select");
                          }}
                          value={field.value}
                        />
                      </div>
                    );
                }
              } else {
                switch (field.type) {
                  case "text":
                    return (
                      <div className="col-12 mt-1 mb-4" key={index}>
                        {this.state.disableField &&
                          this.state.disableField.includes(field.title) &&
                          !this.state.disableTC ? null : (
                          <>
                            <TextField
                              label={
                                <Stack alignment="center" spacing="extraTight">
                                  <span>{field.title}</span>
                                  {/* <TextStyle variation="negative">
                                    {field.required ? "*" : ""}
                                  </TextStyle> */}
                                </Stack>
                              }
                              value={field.value}
                              type={
                                field.title === "Application Secret " &&
                                  !this.state.closeEye
                                  ? "password"
                                  : "text"
                              }
                              error={
                                this.props.validateForm === "Payment" &&
                                  field.title !== "Commission value"
                                  ? this.state.validateForm[field.title]
                                    ? "Enter minimum 3 characters."
                                    : ""
                                  : field.title ===
                                    "Sender Email by which all emails send to supplier"
                                    ? this.state.validateForm[field.title]
                                      ? "Enter Valid Email"
                                      : ""
                                    : ""
                              }
                              suffix={
                                field.title === "Application Secret" ? (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        closeEye: !this.state.closeEye,
                                      });
                                    }}
                                  >
                                    <Icon
                                      source={
                                        this.state.closeEye
                                          ? ViewMinor
                                          : HideMinor
                                      }
                                    />
                                  </span>
                                ) : null
                              }
                              clearButton
                              onClearButtonClick={this.handleChange.bind(
                                this,
                                index,
                                ""
                              )}
                              helpText={field.helpText}
                              placeholder={field.help}
                              onChange={(val) => {
                                this.handleChange(index, val, field.title);
                              }}
                              readOnly={false}
                            />
                          </>
                        )}
                      </div>
                    );

                  case "non_editable":
                    return (
                      <div className="col-12 mt-1 mb-4" key={index}>
                        <Stack distribution="fillEvenly" alignment="center">
                          <b>{field.title} </b>
                          <span>{field.value || "Not Available"}</span>
                        </Stack>
                      </div>
                    );

                  case "multiselect":
                    return (
                      <div className="col-12 mt-1 mb-4" key={index}>
                        <h4>{field.title}</h4>

                        <Popover
                          fullWidth={true}
                          active={this.state.activepop}
                          activator={
                            <Button
                              fullWidth={true}
                              disclosure="down"
                              onClick={() => {
                                this.setState({
                                  activepop: !this.state.activepop,
                                });
                              }}
                            >
                              {field.title}
                            </Button>
                          }
                          onClose={() => {
                            this.setState({ activepop: false });
                          }}
                        >
                          <div className="w-auto p-3">
                            <ChoiceList
                              allowMultiple
                              fullWidth={true}
                              title={field.title}
                              titleHidden
                              choices={field.options}
                              selected={
                                typeof field.value === "string"
                                  ? field.value
                                    .split(",")
                                    .filter((v) => v !== "")
                                  : field.value
                              }
                              onChange={this.handleChange.bind(this, index)}
                            />
                          </div>
                        </Popover>
                      </div>
                    );
                  case "textarea":
                    return (
                      <div className="col-12 mt-1 mb-4" key={index}>
                        <TextField
                          label={field.title}
                          value={field.value}
                          clearButton
                          onClearButtonClick={this.handleChange.bind(
                            this,
                            index,
                            ""
                          )}
                          multiline={4}
                          helpText={field.helpText}
                          placeholder={field.help}
                          onChange={this.handleChange.bind(this, index)}
                          readOnly={false}
                        />
                      </div>
                    );
                  case "image":
                    return (
                      <div className="col-12 mt-4" key={index}>
                        <p>{field.title}</p>
                        <div className="p-3">
                          <Stack distribution="fillEvenly" alignment="center">
                            <Thumbnail
                              source={newUrl + field.value || noProduct}
                              alt={field.title}
                              size="medium"
                            />
                            <input
                              type="file"
                              onChange={(event) =>
                                this.props.onChooseImage(event)
                              }
                              multiple
                              name="file"
                            />

                            <Button
                              loading={this.props.loadingButton[index]}
                              size="slim"
                              submit
                              onClick={() =>
                                this.props.upload(field.code, index)
                              }
                            >
                              Submit
                            </Button>
                            <Button
                              loading={this.props.loadingButton[index]}
                              size="slim"
                              destructive
                              onClick={() =>
                                this.props.deleteImage(
                                  field.code,
                                  index,
                                  "formbuilder"
                                )
                              }
                            >
                              Delete
                            </Button>
                          </Stack>
                        </div>
                      </div>
                    );
                  case "number":
                    return (
                      <div className="col-12 mt-1 mb-4" key={index}>
                        {this.state.disableField &&
                          this.state.disableField.includes(field.title) &&
                          !this.state.disableTC ? null : (
                          <>
                            <TextField
                              label={
                                <Stack alignment="center" spacing="extraLoose" >
                                  <span>{field.title}</span>
                                  {field.title === "Markup Price value" && this.props.showSyncProducts && <Button loading={this.props.syncLoader} size="slim" primary onClick={() => {
                                    this.props.syncProducts()
                                  }}>Sync Products</Button>}
                                </Stack>
                              }
                              value={field.value}
                              type={"text"}
                              clearButton
                              onClearButtonClick={this.handleChange.bind(
                                this,
                                index,
                                ""
                              )}
                              helpText={field.helpText}
                              placeholder={field.help}
                              onChange={(val) => {
                                if( field.code ==="/general/transactionSetting/bsb_number"&&/^[\d-]+$/gm.test(val)){
                                  this.handleChange(index, val, field.title);
                                  return;
                                }

                                if (
                                  (val === "" ||
                                    /^\s*(?=.*[1-9])\d*(?:\.\d{0,2})?\s*$/.test(
                                      val
                                    )) &&
                                  (field.code ===
                                    "/general/commissionSetting/commission_value" ||
                                    field.code ===
                                    "/general/markupPriceSetting/markup_price_value" ||
                                    field.code ===
                                    "/general/vendorTransactionSettings/service_tax_value" ||
                                    field.code ===
                                    "/general/vendorOrderSettings/delayed_fulfillment_fine")
                                ) {
                                  this.handleChange(index, val, field.title);
                                } else if (
                                  /^[-+]?[0-9]+$/.test(val) ||
                                  val === ""
                                ) {

                                  this.handleChange(index, val, field.title);
                                }
                              }}
                              readOnly={false}
                            />
                          </>
                        )}
                      </div>
                    );
                  case "select":
                    return (
                      <div className="col-12 mt-1 mb-1" key={index}>
                        {!this.state.disableaReviews && (
                          <Select
                            label={
                              <Stack>
                                <span>{field.title}</span>
                                {field.mail_code && (
                                  <Tooltip
                                    content={`Click to edit ${field.title} mail template`}
                                  >
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.props.editMail(field.mail_code);
                                      }}
                                    >
                                      <Icon source={EditMinor}></Icon>
                                    </span>
                                  </Tooltip>
                                )}
                              </Stack>
                            }
                            options={field.options}
                            labelInline={false}
                            helpText={field.help}
                            placeholder={"Choose"}
                            disabled={
                              field.code ===
                              "/general/vendorEmail/supplier_account_confirmation_notication"
                            }
                            onChange={(val) => {
                              this.handleChange(
                                index,
                                val,
                                field.code ===
                                  "/general/vendorSettings/vendor_listing" ||
                                  field.code ===
                                  "/general/sellers_review_rating_setting/seller_review_rating"
                                  ? field.code
                                  : field.title,
                                "select"
                              );
                            }}
                            value={field.value}
                          />
                        )}
                      </div>
                    );

                  case "checkbox":
                    return (
                      <div className="col-12 mt-1 mb-1" key={index}>
                        {!field.parent_id}
                        {!field.parent_id && <Label>{field.title}</Label>}
                        <div className="row p-4">
                          {field.options.map((option) => {
                            return (
                              <div
                                className="col-sm-6 col-12"
                                key={field.options.indexOf(option)}
                              >
                                <Checkbox
                                  helpText={field.help}
                                  checked={
                                    field.value &&
                                    field.value.indexOf(option.value) !== -1
                                  }
                                  label={option.label}
                                  disabled={this.manageChild(field)}
                                  onChange={this.handleOptionsChange.bind(
                                    this,
                                    index,
                                    field.options.indexOf(option)
                                  )}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  case "radio":
                    return (
                      <div className="col-12 mt-1 mb-1" key={index}>
                        <Label>{field.title}</Label>
                        <div className="row">
                          {field.options.map((option) => {
                            return (
                              <div
                                className="col-md-4 col-sm-6 col-12"
                                key={field.options.indexOf(option)}
                              >
                                <RadioButton
                                  label={option.label}
                                  helpText={field.help}
                                  checked={option.value === field.value}
                                  id={option.key}
                                  name={option.key}
                                  onChange={this.handleOptionsChange.bind(
                                    this,
                                    index,
                                    field.options.indexOf(option)
                                  )}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                }
              }
            }
          })
        )}

        <div className="col-12 text-right mt-3">
          {/* <Button
            onClick={() => {
              this.setState({ buttonLoading: true });
              setTimeout(() => {
                this.setState({ buttonLoading: false });
              }, 7000);
              this.submitForm();
            }}
            loading={this.state.buttonLoading || this.state.form.length === 0}
            disabled={!this.state.updated}
            primary
          >
            Save
          </Button> */}

          {(this.state.updated || this.props.updatedFee) && (
            <ContextualSaveBar
              message="Unsaved changes"
              discardAction={
                this.props.refresh && {
                  loading: this.state.buttonLoading,
                  content: "Cancel",
                  onAction: () => {
                    this.setState({ buttonLoading: true });
                    setTimeout(() => {
                      this.setState({ buttonLoading: false });
                    }, 7000);
                    this.props.updatedFee && this.props.cancelUpdatedFee();
                    this.setState({ updated: false });
                    // this.props.refresh();
                  },
                }
              }
              saveAction={{
                loading: this.state.buttonLoading,
                content: "Save",
                onAction: () => {
                  this.setState({ buttonLoading: true });
                  setTimeout(() => {
                    this.setState({ buttonLoading: false });
                  }, 7000);
                  this.submitForm();
                  this.props.updatedFee && this.props.saveFee();
                },
              }}
            />
          )}
        </div>
      </div>
    );
  }





  manageChild(child) {
    let { form } = this.state;
    let submitObj = {};
    Object.keys(form).forEach((e) => {
      submitObj[form[e]["code"]] = form[e]["value"];
    });
    if (child["parent_id"] === undefined) return false;
    return submitObj[child["parent_id"]] === child["disabled_on"];
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    let disableTC = false;
    let disableMarketPlace = false;
    let disableaReviews = false;
    nextProps.form &&
      nextProps.form.map((field) => {
        if (
          (field.title === "Require terms & conditions" ||
            field.title === "Apply Fee for Delayed Fulfilment" ||
            field.title === "Enable Supplier Membership") &&
          field.value == "yes"
        ) {
          disableTC = true;
        }
        if (
          field.code === "/general/vendorSettings/vendor_listing" &&
          field.value === "no"
        ) {
          disableMarketPlace = true;
        }
        if (
          field.code ===
          "/general/sellers_review_rating_setting/seller_review_rating" &&
          field.value === "no"
        ) {
          disableaReviews = true;
        }
      });
    this.setState({
      form: nextProps.form,
      buttonLoading: nextProps.buttonLoading,
      disableTC: disableTC,
      disableMarketPlace: disableMarketPlace,
      disableaReviews: disableaReviews,
      disableField: nextProps.disableField,
    });
  }

  submitForm() {
    let { form } = this.state;
    let submitObj = {};
    Object.keys(form).forEach((e) => {
      submitObj[form[e]["code"]] = form[e]["value"];
    });
    this.setState({ updated: false });

    var exists = Object.keys(this.state.validateForm).some((k) => {
      return this.state.validateForm[k] === true;
    });
    this.props.validateForm
      ? this.props.onSubmit(submitObj, exists)
      : this.props.onSubmit(submitObj, false);
  }

  handleChange(fieldIndex, value, title, select) {
    this.setState((state) => {

      state.form[fieldIndex].value = value;
      state.updated = true;

      if (
        select &&
        (title === "Require terms & conditions" ||
          title === "Apply Fee for Delayed Fulfilment" ||
          title === "Enable Supplier Membership")
      ) {
        state.disableTC =
          (title === "Require terms & conditions" ||
            title === "Apply Fee for Delayed Fulfilment" ||
            title === "Enable Supplier Membership") &&
            value === "yes"
            ? true
            : false;
      } else if (select && title === "/general/vendorSettings/vendor_listing") {
        state.disableMarketPlace = value === "no" ? true : false;
      } else if (
        select &&
        title === "/general/sellers_review_rating_setting/seller_review_rating"
      ) {
        state.disableaReviews = value === "no" ? true : false;
      }
      if (
        this.props.validateForm === "Payment" &&
        title !== "Commission value" &&
        title !== "Payment cycle (in days)" &&
        title !== "Bank Transfer Active"
      ) {
        if (title==="BSB number") {
          state.validateForm[title] = /^[\d -]+$/gm.test(value);
        }else{
          state.validateForm[title] = !/^.{3,1000}$/.test(value);
        }
      }
      if (title === "Sender Email by which all emails send to supplier") {
        state.validateForm[title] =
          !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
            value
          );
      }
      return state;
    });
  }

  handleOptionsChange(fieldIndex, optionIndex, value) {
    const keyValue = this.state.form[fieldIndex].options[optionIndex].value;

    this.state.updated = true;
    switch (this.state.form[fieldIndex].type) {
      case "checkbox":
        if (this.state.form[fieldIndex].value == null)
          this.state.form[fieldIndex].value = [];
        const keyIndex = this.state.form[fieldIndex].value.indexOf(keyValue);
        if (value) {
          if (keyIndex === -1) {
            this.state.form[fieldIndex].value.push(keyValue);
          }
        } else {
          if (keyIndex !== -1) {
            this.state.form[fieldIndex].value.splice(keyIndex, 1);
          }
        }
        break;
      case "radio":
        this.state.form[fieldIndex].value = keyValue;
        break;
    }
    const state = this.state;
    this.setState(state);
  }
}

Formbuilder.propTypes = {
  form: PropTypes.array.isRequired,
  buttonLoading: PropTypes.bool,
  refresh: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  sync: PropTypes.bool,
};

export default Formbuilder;
