import { requests } from "../services";

// Action to Re-sync necessary Info
export const syncNecessaryInfo = () => (dispatch) => {
  requests.postRequest("frontend/app/getNecessaryDetails").then((e) => {
    // console.log(e, "eeeeeeeeeeeeee");
    if (e.success) {
      let account_connected_array = e["account_connected"].map((e) => e.code);

      let catalog_sync_only = "no";

      if (e["form_details"] && e["form_details"]["catalog_sync_only"] === 1) {
        catalog_sync_only = "yes";
      }

      localStorage.setItem("catalog_sync_only", catalog_sync_only);

      dispatch({
        type: "syncNecessaryInfo",
        state: {
          account_connected: modifyAccountConnectedInfo(
            account_connected_array
          ),
          services: e["services"],
          account_connected_array: account_connected_array,
          username: e["shop_owner"],
          shop_url: e["shop_url"],
          resource: e["resource"],
          form_details: e["form_details"],
          catalog_sync_only: catalog_sync_only,
        },
      });
    }
  });
};

export const syncPageInfo = () => (dispatch) => {
  // requests
  //   .postRequest("facebookhome/request/getFacebookPage", [{ type: "business" }])
  //   .then((e) => {
  //     if (e.success) {
  //       let warehouses = e["warehouses"];
  //       let stateWarehouse = [];
  //       if (
  //         Object.keys(warehouses).length > 0 &&
  //         e["data"] &&
  //         Object.keys(e["data"]).length > 0
  //       ) {
  //         Object.values(warehouses).forEach((warehouse) => {
  //           Object.values(e["data"]).forEach((page) => {
  //             if (warehouse["merchant_page"]["id"] === page["id"]) {
  //               stateWarehouse = [
  //                 ...stateWarehouse,
  //                 {
  //                   warehouse: warehouse,
  //                   page: page
  //                 }
  //               ];
  //             }
  //           });
  //         });
  //       }
  //       dispatch({
  //         type: "syncPageInfo",
  //         state: {
  //           warehouse: stateWarehouse
  //         }
  //       });
  //     }
  //   });
};

export const showNotification =
  (message, error = false) =>
  (dispatch) => {
    dispatch({
      type: "showToast",
      state: {
        error: error,
        message: message,
      },
    });
  };

export const hideNotification = (id) => (dispatch) => {
  dispatch({
    type: "hideToast",
    state: {
      id: id,
    },
  });
};

export const themeChange = (type) => (dispatch) => {
  localStorage.setItem("cedTheme", type);
  dispatch({
    type: "theme",
    state: {
      type: type,
      newDesign: type !== "old",
    },
  });
};

function modifyAccountConnectedInfo(accounts) {
  let value = [];
  accounts.forEach((e) => {
    let title = e;
    switch (e) {
      case "fileimporter":
        title = "Csv";
        break;
      case "wishimporter":
        title = "Wish";
        break;
      case "magento":
        title = "Magento 2";
        break;
      case "M1":
        title = "Magento 1";
        break;
      case "bigcommerce":
        title = "Bigcommerce";
        break;
      case "wix":
        title = "WIX";
        break;
      default:
        title = e;
    }
    if (e !== "facebook") {
      value.push({
        title: title,
        code: e,
      });
    }
  });
  if (value.length === 0) value.push({ title: "Shopify", code: "shopify" });
  return value;
}
