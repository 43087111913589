import React, { Component } from "react";
import { Page, Card, Scrollable, Label } from "@shopify/polaris";

class PrivatePolicy extends Component {
	render() {
		return (
			<Page>
				<Card>
					<div className="row p-5">
						<div className="col-12 text-center mb-5">
							<h1>Magento Migrator</h1>
							<h2>Privacy Policy</h2>
						</div>
						<Card sectioned>
							<Scrollable shadow style={{ height: "500px" }}>
								<div className="row">
									<div className="col-12 text-center">
										<span
											className="font-weight-bold "
											style={{ fontSize: "25px", color: "#4d4849" }}
										>
											{" "}
											CedCommerce Terms &amp; Condition and Privacy Policy
										</span>
										<div className="text-left">
											<Label>
												<p>
												CedCommerce, LLC currently provides migration functionality with Magento Migrator app. Our goal is to provide reliable services for a competitive price that helps merchants to migrate their store from Magento to Shopify. We work hard to ensure this service works to its full potential and meet each case with the utmost dedication.
	
												</p>
												<p>
													We understand how important a secure website is to e-commerce. Our investment in infrastructure and security only highlights this commitment. Unfortunately, our service is reliant on 3rd party providers that may have other measures in place. Therefore, we cannot guarantee a 100% error free code or website that is accessible at all times. Our service is not liable for any damages or loss of income, revenue or data. Additionally, there is no guarantee that the error in the code or functionality will prevent the suspension or deletion of your store. 

												</p>
												<p>
													As a user of CedCommerce products you are responsible for your account as well as any content related to your account.

												</p>
												<p>
													Furthermore, you agree to use CedCommerce in compliance with all applicable laws and will in no way violate the Shopify Terms of Use or Seller Agreement. 

												</p>
												<p>
													Furthermore, CedCommerce does not hold responsibility for any illegal use and reserve the right to close the store for any reason.

												</p>
												<p>
													CedCommerce LLC offering use of the service is expressly conditioned on your acceptance of these CedCommerce Terms and Condition. By using the Service, you signify that you unconditionally agree and accept to be legally bound by these Terms and Condition. No other terms or conditions of any sort in any document, writing or other communication whatsoever made by you to CedCommerce or its employees, representatives or agents in relation to the Service shall be applicable to orbinding on CedCommerce.
												</p>
											</Label>
											<span
												className="font-weight-bold"
												style={{ fontSize: "25px", color: "#4d4849" }}
											>
												Updates{" "}
											</span>
											<Label>
												<p>
													We may change these Terms of Use from time to time, by posting updates to our website. An update will be effective for any website use after the date of the update. We encourage you to review our Terms of Use from time to time for possible changes. Your use of our website after an update constitutes your agreement to the update.

												</p>
												<p>
													
												</p>
											</Label>
											<span
												className="font-weight-bold"
												style={{ fontSize: "25px", color: "#4d4849" }}
											>
												Payment Terms{" "}
											</span>
											<p>
											CedCommerce reserves right and would charge you with $20/hour as a customization charge for the additional on-demand benefits/requests made from your end for improving or enhancing the functionality. 

											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												subscription{" "}
											</span>
											<p>
											Some of CedCommerce Services are billed on a subscription basis means that you will be billed in advance on a recurring, periodic basis (each period is called a billing cycle.) Your Subscription will automatically renew at the end of each billing cycle unless you cancel auto-renewal through your online account management page, or by contacting our customer support team. While we will be sad to see you go, you may cancel auto-renewal on your Subscription at any time, in which case your subscription will continue until the end of that billing cycle before terminating. You may cancel auto-renewal on your subscription immediately after the subscription starts if you do not want it to renew.</p>
										<p>
										We may change the subscription fee charged for the services at any time. The change will become effective only at the end of the then-current billing cycle of your subscription.
										</p>
										<p>
										Under the Free Subscription Plan, only 10 products, 10 orders and 10 customers data would be included. In order to continue with the service you need to subscribe for the Paid Plan.</p>
										<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												Refund{" "}
											</span>
											<p>
											You are responsible for keeping your billing information up to date and your account current. You will not be liable for any sort of partial or prorated refund of your subscription fee for any time during which you do not use the Services. 
											</p>
											<p>
											The refund would not be applicable to the boundaries beyond the operational field and the issues/concerns/liabilities not under the CedCommerce service policies and norms. Sales of Products on store entirely depends on your product and Purchasing Client. 

											</p>
											<p>
											App Provides System and Tools to make Sale Easier but does not guarantee sales. No Refund Policy is applicable in such scenarios where App is considered to generate revenue on your store. 

											</p>
											<p>
											In case any partial or complete refund is made to any users then his account would be completely removed from our records. No support would be provided to the item that got published on Shopify through our app.

											</p>
											<p>Under the Free Subscription Plan, only 10 products, 10 orders and 10 customers data would be included. In order to continue with the service you need to subscribe for the Paid Plan.</p>
										<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
											{" "}	When You Can't Claim The Refund.
                                          
											</span>
											<ul>
											<li>No refund will be initiated for the customization/support work. Forcases where sellers receive orders of wrong items (due to wrong information), refund request won't be entertained.</li>
											<li>If within trial days of the installation of the app, no issues are raised from the sellers, it will be deemed that sellers are familiar with app functionalities and satisfied with its operation, therefore no refund request will be entertained.</li>
											</ul>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "} Usage of the Service

											</span>
											<p>
											You are responsible for your account, content, and communications with others while using the Services. You agree to use the Services in compliance with applicable law. This is your responsibility and you agree to not use CedCommerce in a way that violates Shopify Terms of Use. By using CedCommerce app, you agree to not violate any of Shopify terms. We are also not responsible for any illegal use of CedCommerce by our users. 


											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "} Communication Policy

											</span>
											<p>
											We understand the value of your time and money and expect the same from you. We expect that if you are providing any contact details at the time of registration, it will be used by us to communicate with you in future. 

											</p>
											<p>We can use your contact details for the following purpose.</p>
											<ul>
											<li> To inform you about latest updates about app and store.</li>
											<li> To inform you about any issue of app or your store.</li>
											<li> Inform you about your trial and subscription</li>
											<li>Introducing any new product.</li>
											<li>Strategies to boost up your business.</li>
											</ul>
											<p>We hope you will also be keep in touch and cooperate with us. </p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}Refund Policy

											</span>
											<p>
											We respect your thoughts and of course we will always agree with your decision. In case you are not finding us useful for you, first you have to communicate properly with us so that we can understand your problem.We assure we will never let you go with dissatisfaction.</p>
											<p>
											We have provided many mediums through which you can easily connect with us- Skype, Live Chat, Mail, Call, WhatsApp, WeChat and Ticket,etc. We will resolve your each and every issue within 3-5 business days with the help of proper communication and details provided by your side. A healthy negotiation is the key to fruitful business and of course you will find the same. We guarantee, you will not have any delay or lack of perfectness from our end but as we also depend upon your store, so we can't guarantee about the same.

											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}Account Termination

											</span>
											<p>
											CedCommerce has the right to terminate its relationship with you, without prior notice, if you breach or fail to comply with any provision of these Terms and Condition. This Agreement and your ability to use the Service shall also automatically terminate upon the expiration of your subscription.


											</p>
											<p>
											CedCommerce has no responsibility to maintain your user account or any of your data after termination. CedCommerce is not responsible for any fees, damages, or claims you may suffer in relation to the same or any claims or actions you may have as a result of termination.If termination is due to your failure to renew your subscription on a timely basis, you may reactivate your account and access your data if you renew your subscription with CedCommerce. To do so you may have to pay any subscription fees or charges that may be imposed by CedCommerce,including reactivation fees (if any), within any period that may be stated by CedCommerce. Failure to pay activation fees may result in your user account being deleted, and permanent removal from its systems.


											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}Your Data

											</span>
											<p>
											You or your customers, partners or associates whichever may be applicable are the owners of any data you upload into the Service Your Data. By Your Data, we mean images of your products, your personal and company information, products, customers, and sales information. You hereby agree that CedCommerce is the owner of all data other than Your Data,including any system generated data generated by the Service or any data compiled from data inputted into the Service by all users of the Service on an aggregate basis (CedCommerce Data. CedCommerce may use all of CedCommerce Data in any way it chooses (including to improve or adapt its services), or to create or design new products and services.

											</p>
											<p>
											We own these global statistics (which do not contain any information that can be clarified as "Your Data"). You acknowledge and agree that the nature of the Internet is international and that CedCommerce has your express consent to store and provide access to your personal or confidential information, and that of your users(s) and customers, and to transmit and deliver such information via the Internet (which may involve its transmission across multiple jurisdictions).

											</p>
											<p>
											You are responsible for all data on your account and you agree to comply with all applicable legal requirements for the sale, transfer, and transport of an item, including but not limited to statutes, regulations or requirements of any country,state, locality, province, municipality or other government authority or regulatory entity regarding sales or auctions, the sale and/or transfer of any Item (including firearms, ammunition, black powder, or any other item), export or import control, taxation, duties or tariffs, presence or licensing of brokers (the foregoing, Legal Requirements) governing the specific requirements for transfer and shipping of firearms.CedCommerce is not responsible for the seller's products, nor is liable for any legal actions that may result from the sale of the seller's products.
											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}Your Account and Password


											</span>
											<p>
											It is your responsibility to keep secure and confidential any password(s)and user ID(s) CedCommerce may issue to you to access the Service,rests solely with you and the employees, representatives, and agents of yours that are entrusted with the same. If you become aware of any unauthorized access to your account(s), or any misuse of your passwords)and user ID(s), you must follow the CedCommerce process to disable your account(s) and/or re-issue new password(s) or user ID(s) as soon aspossible.You agree that you are solely responsible for the actions and omissions of the person(s) you nominate as a user(s) or administrator(s) of your account(s) for the Service. You also agree that CedCommerce mayaccept instructions and requests from, and communicate with such person(s) until and unless CedCommerce receives notification thatsuch person(s) that this is no longer in effect. At this point, all requests and communications are valid and legally binding on you. In addition, you are responsible for any and all use of your account(s) by any persons who are in possession of your user ID(s) or password(s).

											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}No Warranties



											</span>
											<p>
											UNLESS EXPRESSLY PROVIDED HEREIN, TO THE FULLEST EXTENT PERMITTED BY LAW, CEDCOMMERCE MAKES NO WARRANTY OR REPRESENTATION OF ANY KIND REGARDING ITS WEBSITE, THE SERVICE,THE PRODUCTS OR SERVICES AVAILABLE ON THIS WEBSITE AND/ORANY MATERIALS PROVIDED ON THIS WEBSITE, ALL OF WHICH ARE PROVIDED ON AN AS IS WHERE IS BASIS.

											</p>
											<p>
											CEDCOMMERCE DOES NOT WARRANT THE ACCURACY,COMPLETENESS, CURRENCY OR RELIABILITY OF ANY OF THE CONTENT OR DATA FOUND ON THE SERVICE OR THIS WEBSITE. CEDCOMMERCE EXPRESSLY DISCLAIMS ALL WARRANTIES, AND TERMS AND CONDITION IN RELATION TO THE SERVICE,INCLUDING ALL IMPLIED WARRANTIES AS TO MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A GENERAL OR PARTICULAR PURPOSE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS, AND THOSE ARISING BY STATUTE OR OTHERWISE IN LAWOR FROM A COURSE OF DEALING OR USAGE OF TRADE TO THE FULLEST EXTENT PERMITTED BY THE LAWS OF NEW YORK and the United States of America.


											</p>
											<p>
											CEDCOMMERCE DOES NOT WARRANT THAT THE SERVICE, THIS WEBSITE, ITS SERVERS OR ANY EMAIL SENT FROM CEDCOMMERCE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
											</p>
											<p>
											FOR THE AVOIDANCE OF DOUBT, CEDCOMMERCE DOES NOT GUARANTEE THE EFFECTIVENESS OF THE SERVICE.

											</p>
											<p>
											We cannot also guarantee that errors in the code or functionality will not cause your store to be suspended or deleted. CedCommerce TAKES NO RESPONSIBILITY FOR THE SECURITY,CONFIDENTIALITY OR PRIVACY OF THE COMMUNICATIONS AND/OR DATA TRANSMITTED OVER THE INTERNET AND DOES NOT WARRANT (AND EXPRESSLY EXCLUDES ANY AND ALL EXPRESSOR IMPLIED WARRANTIES) THAT THE SERVICE WILL BE WITHOUT FAILURE, DELAY, INTERRUPTION, ERROR OR LOSS OF CONTENT,DATA OR INFORMATION. IN ADDITION, CEDCOMMERCE SHALL NOT BE LIABLE FOR ANY COMPATIBILITY ISSUES PERTAINING TO CUSTOMERS TM COMPUTERS, APPLICATIONS OR OTHERSOFTWARE ON ANY COMPUTERS USING THE SERVICE.

											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}Limitation of Liability




											</span>
											<p>
											TO THE FULLEST EXTENT PERMITTED BY LAW IN NO EVENT SHALL CEDCOMMERCE BE LIABLE FOR ANY INJURY, LOSS, CLAIM, DAMAGE,OR ANY SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND OR FOR ANY LOST PROFITS OR LOST SAVINGS, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), EQUITY, STRICT LIABILITY, STATUTE OR OTHERWISE,WHICH ARISES OUT OF OR IS IN ANY WAY CONN CONTENT FOUNDHEREIN, (II) ANY FAILURE OR DELAY (INCLUDING, BUT NOT LIMITED TO THE USE OF OR INABILITY TO USE ANY COMPONENT OF THE SERVICE ORTHIS SITE), OR (III) THE PERFORMANCE OR NON PERFORMANCE BY CEDCOMMERCE EVEN IF THE CEDCOMMERCE HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES TO SUCH PARTIES OR ANY OTHER PARTY.

											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}Force Majeure





											</span>
											<p>
											CedCommerce has no responsibility for and is released from all contractual obligations and liability (e.g. for damages) if its performance of these Terms and Condition is affected by an event of force majeure. For the purpose of this clause, the term force majeure means and includes anyevent which was not under the control of CedCommerce, or was not reasonably foreseeable, including, but not limited to any natural disaster such as thunderstorm, flood or storm, fire, national emergency, strike or equivalent labor action, or the unavailability of the Internet for reasons beyond the control of CedCommerce.

											</p>
											<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}Jurisdiction






											</span>
											<p>
											This Agreement shall be construed and governed in accordance with the laws of India, and the parties hereto submit to the exclusive jurisdiction of the courts of India.Thank you for taking the time to understand our Terms & Condition. Any questions regarding the Terms and condition should be addressed to apps@cedcommerce.com​.

											</p>






<span
												className="font-weight-bold"
												style={{ fontSize: "18px", color: "#4d4849" }}
											>
												
                                       {" "}Jurisdiction



											
											
												PRIVACY POLICY
											</span>
											<Label>
												<p>
													Purpose CedCommerce an ecommerce wing of CedCoss
													Technologies Pvt Ltd , 3/460 Vishwas Khand Gomti Nagar
													Lucknow India respects the privacy of its clients and
													the website visitors.
												</p>
												<p>
													This Privacy Policy has been created to make you aware
													of what information we may collect from you and how
													you shared information is being used from all our site
													(https://cedcommerce.com/) and any of the social media
													pages. https://www.facebook.com/cedcommerce
													https://plus.google.com/+Cedcommerce-Marketplace
													https://twitter.com/cedcommerce
													https://www.linkedin.com/company/cedcommerce
												</p>
												<p>
													We do our every bit do protect customer information
													and from time to time make changes to the privacy
													policy which through a notification is being displayed
													on the website. Regular visitors are aware of these
													things and for everyone going through the website make
													sure to go through the privacy policy page before
													sharing any information.The website is accessible
													through multiple devices and this privacy policy
													applies to all such access.
												</p>
												<p>
													What Information Do We Collect ? Any information that
													you share while processing any order on the website ,
													website page visits such as payment information ,
													shipping information , products you showed interest
													and personal information like name,age,address,email
													address, mobile number with a verification call,,
													which page you visited ,Skype Details , products
													purchased , how do you came to know about us , Source
													of Shipping will be collected by us. We may use these
													information to contact you to provide suitable
													information. Password related information is stored in
													an encrypted. We will also collect details such as
													FTP, API details & Server/ WebSite details for
													development purposes as well as to provide good
													support.
												</p>
												<p>
													Once the work is complete or for any other reason it
													is your responsibility that you change the
													credentials.
												</p>
												<p>
													When you are providing information you fill a terms
													and condition and only after that those information
													reaches to us. As a backend administrator we will able
													to have an access of your backend as long as you stay
													as our customer whereby we will able to access your
													profile and make the necessary changes.Time to time we
													make changes to the policy so you should regularly
													revisit to be aware of such changes.
												</p>
												<p>
													We notify about such changes by notification at the
													top of the website. We will also store information
													such as Browser Information , IP address, operating
													system , location , date and automatically store the
													same. We don’t collect personal information from
													children under age of 13 and should not use the
													website.
												</p>
											</Label>
											<span
												className="font-weight-bold"
												style={{ fontSize: "25px", color: "#4d4849" }}
											>
												Use of Information
											</span>
											<Label>
												We will use your contact information that is being
												gathered from you for the following purposes :
												<br />
												<br />
												● Order and Transaction Related Information
												<br />
												● Sending Associated Product Emails based on the
												interest shows
												<br />
												● Sending New Product Information
												<br />
												● Product Related and Major Updates
												<br />
												● Improving the overall structure of site to give
												personalized experience based personal traits
												<br />
												● Payment Related Telephonic Conversations
												<br />
												● Feedback related Mails and Calls
												<br />
												● Informational Mails and Calls
												<br />
												● Scheduled Calling
												<br />
												● Service Related Mails
												<br />
												● Notification Mails
												<br />
												● Text Messaging Service
												<br />
												There is always a procedure for unsubscription to
												receiving such emails and newsletters and after doing so
												you won’t be receiving such information.
											</Label>
											<span
												className="font-weight-bold"
												style={{ fontSize: "25px", color: "#4d4849" }}
											>
												How do we deal with 3rd Parties?
											</span>
											<Label>
												<p>
													Any associated affiliate for may receive the personal
													information shared and they may contact you on for
													offering there solution and services.
												</p>
												<p>
													Associated Partners will receive the necessary
													information of the clients in order to reach out to
													them to offer the complete service solution.
												</p>
												<p>
													We don’t allow any 3rd Parties to conduct contest and
													surveys and even if they do then we may prohibit them
													from using the information for any other purpose In
													the event of the sale of business all the information
													would be transferred but before that information would
													be shared with you related to the same. We may
													conducts advertisements and use third party tool and
													share information including name, adress, email
													address , telephone number to show tailored based
													advertisements of product that may suit your purpose.
												</p>
												<p>
													We reserve the right to disclose your personal
													information as required by law, and as necessary to
													protect the property, interests and rights of
													CedCommerce and the CedCommerce Affiliates. The
													CedCommerce website might contain links to the 3rd
													Parties Websites. Those 3rd Party Website may use
													cookies in order to gather information . This privacy
													policy does not cover their privacy setup. So we
													suggest to go through each site privacy policy
													separately. We will use third party software like
													MailChimp, Hot Jar, Live Chat Facilitators, Google
													Analytics and Google Adwords to track the visits of
													the prospects and for remarketing and retargeting
													purpose.
												</p>
											</Label>
											<span
												className="font-weight-bold"
												style={{ fontSize: "25px", color: "#4d4849" }}
											>
												{" "}
												Choice to Opt In / Opt Out
											</span>
											<Label>
												<p>
													We want our voice reaches only to those who really
													want to listen. In case you don’t want to receive any
													information from us, you can go for a solution. We
													have provided a way, you can always reach us to at
													apps@cedcommerce.com and inform that you don't want to
													receive any information from our side and we would
													stop communicating with you.
												</p>
												<p>
													You can also inform us if you don't want to use our
													services any more. At any time the client can opt out
													of any service by removing the software and can also
													delete / change any information which he has provided
													earlier. You could also call us at - (+91) -7234976892
													or toll-free number at : 888-882-0953 (FOR USA). What
													are Cookies and What Information does it gathers ?
													Cookies are small piece if information that is
													transferred to your computer as soon as you visit a
													website. We use traffic log cookies for statistical
													analysis in order to tailor it your needs. Once the
													purpose is achieved, the required data is immediately
													removed from our system. In fact, cookies further our
													objective to allow you take complete advantage of our
													site You can always change your setting whereby you
													will be notified whenever a site is using cookies and
													it will depend on your acceptance whether information
													is transferred or not. Cookies used by affiliates and
													partners are not covered under this privacy policy.
													There are various types of cookie: Strictly necessary
													cookies :​ These are cookies that are required for the
													operation of our Website. For example, cookies that
													enable users to log into only secure areas of our
													Website. Analytical/performance cookies: ​ These types
													of cookies allow us to recognize, count the number of
													visitors, and see how visitors move around our Website
													when they are using it. This assists us to improve the
													way in which our Website works, for example, by
													ensuring that you can find what you are looking for
													easily. Functionality cookies :​ These cookies are
													used to recognize you when you return to our Website.
												</p>
												<p>
													They enable the personalization of content,
													recognition of users, and also remember your user
													preferences (for example, your choice of language or
													region) Session Cookies :​ Session cookies exist only
													during an online session.
												</p>
												<p>
													They disappear from your computer when you close your
													browser or turn off your computer. We use session
													cookies to allow our systems to uniquely id entify you
													during a session or while you are logged into the
													Website or Services.
												</p>
												<p>
													This allows us to process your online transactions and
													requests and verify your identity, after you have
													logged in, as you move through our Website or
													Services.
												</p>
												<p>
													Updation in Personal Information / Inquiries /
													Complaints In case you want any changes in the
													information you could mail us at apps@cedcommerce.com
													.
												</p>
												<p>
													Alternatively you could write us at : CedCommerce Inc
													3/460 Vishwas Khand Gomti Nagar Lucknow – 226010
													Security & Term of Information There is no fixed term
													or duration where we will be keeping the information.
													We may keep as long as it is required for the purpose
													mentioned in the policy and as governed by the Law.
													CedCommerce.com in encrypted formusing
													industry-standard Secure Sockets Layer (SSL)
													connections to help protect such information from
													interception.
												</p>
											</Label>
										</div>
									</div>
								</div>
							</Scrollable>
						</Card>
					</div>
				</Card>
			</Page>
		);
	}
}

export default PrivatePolicy;
